import React from "react"
import styled from "styled-components"

import SaveTimeDesktopPNG from "./SaveTimeDesktop.png"
import SaveTimeDesktopWEBP from "./SaveTimeDesktop.webp"
import SaveTimeMobilePNG from "./SaveTimeMobile.png"
import SaveTimeMobileWEBP from "./SaveTimeMobile.webp"

import { Row, Col, Img } from "./style"
import { ContainerPage, WrapperPage } from "src/componentsV2/ui/layout"
import { Title, SubTitle } from "src/componentsV2/sections/Text"

const AutoPolicyUpdatesContainer = styled(ContainerPage)`
  padding-top: 0px;
  @media (min-width: 768px) {
    padding-top: 120px;
    padding-bottom: 25px;
    #desc {
      width: 350px;
    }
  }
`

const index = () => {
  return (
    <AutoPolicyUpdatesContainer isBackgroundWhite={true} data-loadable-component="auto-policy-updates-save-time">
      <WrapperPage>
        <Row className="items-center">
          <Col className="description">
            <div style={{paddingTop: "35px"}}>
            <Title className="title">
            Could your time be spent better than updating handbook policies?
            </Title>
            <SubTitle className="px-4 md:px-0 mb-5 md:mb-5">
              <div id="desc" className="mx-auto md:mx-0">
                Save your time and energy. AirMason’s AI driven, automated policy updates keep your employee handbook compliant and up-to-date.
              </div>
            </SubTitle>
            </div>
          </Col>
          <Col className="text-center md:text-right">
            <picture>
              <source srcSet={SaveTimeDesktopWEBP} type="image/webp" media="(min-width: 769px)" />
              <source srcSet={SaveTimeDesktopPNG} type="image/jpg" media="(min-width: 769px)" />
              <source srcSet={SaveTimeMobileWEBP} type="image/webp" />
              <source srcSet={SaveTimeMobilePNG} type="image/jpg" />
              <Img src={SaveTimeDesktopPNG} width="800" height="412" alt='handbook apu' />
            </picture>
          </Col>
        </Row>
      </WrapperPage>
    </AutoPolicyUpdatesContainer>
  )
}

export default index
